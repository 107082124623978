/* @flow */

import { createContext } from 'react';

import type { CustomVideoContext } from 'src/shared/app/base/plugin/virtual-braindate/type/virtualTypes';

export default createContext<CustomVideoContext>({
  isSponsorOpen: false,
  layout: 'layout_one',
  hasLayoutMenu: false,
  setLayout: () => {},
  // $FlowIssue
  conversation: {},
  // $FlowIssue
  topic: {},
  // $FlowIssue
  braindate: {},
  onReport: () => {},
  onSponsorDismiss: () => {},
  onHide: () => {},
  // $FlowIssue
  event: {},
  isHidden: false,
  onActivateFeedbackForm: () => {},
  onClose: () => {},
  onBack: () => {},
  dispatchJoinCall: () => {},
  isJoining: false,
  sponsors: [],
  isParticipantSidebarOpen: false,
  setIsParticipantSidebarOpen: (_status) => {},
  isChatSidebarOpen: false,
  setIsChatSidebarOpen: (_status) => {},
  isAgendaSidebarOpen: false,
  setIsAgendaSidebarOpen: (_status) => {},
  displayedAgenda: null,
  setDisplayedAgenda: (_status) => {},
  isDisplayingAgendaBubble: false,
  setIsDisplayingAgendaBubble: (_status) => {},
  refControls: { current: null },
  isDisconnecting: { current: false },
  isFishbowl: false,
  onStop: () => {},
  isSharingScreen: false,
  closedCaption: {
    handleToggle: () => {},
    isActive: false,
    isFetching: false,
    partialText: '',
    fullText: '',
    error: undefined,
    clearError: () => {},
    handleTrackRegistration: (_track) => {},
    handleTrackUnregistration: (_track) => {},
  },
});
